.containerCard {
  display: flex;
  width: 100%;
  border-radius: 5px;
  gap: 20px;
  transition: all 0.3s ease;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  .card {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    transition: all 0.3s ease;
    background-color: #fff;
    padding: 16px 20px;
    width: 370px;

    min-height: 180px;
    cursor: pointer;
    align-items: flex-start;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
    &:hover {
      transform: translateY(-5px);
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 1);
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
    .icon {
      font-size: 40px;
      color: #8e6ec8;
      margin-bottom: 14px;
    }
    .title {
      font-size: 20px;
      color: #201646;
      margin-bottom: 8px;
    }
    .drescription {
      font-size: 16px;
      color: #000;
    }
  }
}
