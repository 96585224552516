/* Gloabl Styles.module.css */
@import '/src/styles/GlobalStyles.scss';
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  @include screen(mobile) {
    flex-direction: column;
    gap: 20px;
  }
}
.containerButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 36px;
  button {
    height: 48px;
    padding: 0 24px;
    min-width: 100px;
    border: 1px solid grey;
    outline: none;
    border-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    &.upload {
      background-color: green;
    }
    &.cancel {
      background: red;
    }
  }
}

.buttonSubmit {
  height: 48px;
  padding: 0 24px;
  min-width: 250px;
  background: #201646;
  outline: none;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  margin-top: 50px;
  border: 0;
  &:disabled {
    background-color: rgba($color: #201646, $alpha: 0.3);
    cursor: not-allowed;
  }
}
