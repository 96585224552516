.datepickerContainer {
  display: flex;
  padding: 3px 10px;
  align-items: center;
  border: 1px solid #c9c9c9;
  background: #fff;
  gap: 10px;
  border-radius: 5px;
  .calendarSection {
    display: flex;
    gap: 10px;
    align-items: center;
    .calendarIcon {
      font-size: 24px;
    }
  }
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #5f5f5f;
  }
  .dateRange {
    display: flex;
    gap: 10px;
    align-items: center;
    .dateRangeIcon {
      font-size: 24px;
    }
  }
  .datePickerWrapper input {
    padding: 7px 10px;
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    outline: none;
    max-width: 100px;
  }
}
