.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  /* Hace que el fondo sea semi-transparente */
  * {
    box-sizing: border-box;
  }
}

.form {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 5px;
  img {
    width: 100%;
    margin-bottom: 30px;
  }
}

.formGroup {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 5px;
}

.input {
  width: 100%;
  padding: 12px 24px;
  border: 1px solid #afa7d1;
  border-radius: 5px;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

.button {
  width: 100%;
  padding: 16px 24px;
  background-color: #201646;
  color: #fff;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #201646;
}
