$size__site_content_width: 1024px;

/* Media Queries */
$media_queries: (
  'mobile': 'only screen and (max-width: 667px)',
  'tablet': 'only screen and (min-width: 668px) and (max-width: 1200px)',
  'desktop': 'only screen and (min-width: 1200px)',
  'retina2':
    'only screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)',
  'retina3':
    'only screen and (-webkit-min-device-pixel-ratio: 3) and (min-resolution: 288dpi)',
  'landscape': 'screen and (orientation:landscape)',
  'portrait': 'screen and (orientation:portrait)',
);

@mixin screen($breakpoints) {
  $conditions: ();
  @each $breakpoint in $breakpoints {
    // If the key exists in the map
    $conditions: join($conditions, map-get($media_queries, $breakpoint), comma);
  }

  @media #{$conditions} {
    @content;
  }
}
.content-dashboard {
  padding: 30px;
}
.container {
  margin: 0 auto;
  padding: 15px;
}

.button {
  &-primary {
  }
}
