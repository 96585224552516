/* Gloabl Styles.module.css */
@import '/src/styles/GlobalStyles.scss';
.uploadFile {
  display: flex;
  align-items: center;
  @include screen(mobile) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
}
.button {
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  box-shadow: none;
  background-color: transparent;
  color: #515c6f;
  margin: 10px 0 0 !important;
  border: 1px solid #515c6f;
  border-radius: 5px;
  height: 48px;
  min-width: 270px;
  img {
    transition: all 0.1s linear;
  }
  &:hover {
    background-color: #515c6f !important;
    color: #fff;
    img {
      filter: invert(100%);
    }
  }
}

.nameFile {
  margin-left: 10px;
}
.icon {
  max-width: 25px;
  margin-left: 10px;
}
.nameDoc {
  min-width: 220px;
  display: inline-block;
  font-weight: bold;
  margin: 0;
}

.uploadButton {
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  box-shadow: none;
  background-color: transparent;
  color: #515c6f;
  margin: 10px 0 0 !important;
  border: 1px solid #515c6f;
  border-radius: 5px;
  height: 48px;
  min-width: 270px;
  position: relative;
  cursor: pointer;
  transition: all 0.1s linear;
  &.uploaded {
    background-color: #038603;
    color: #fff;
    img {
      filter: invert(100%);
    }
  }
  @include screen(tablet mobile) {
    min-width: fit-content;
    padding: 0 15px;
  }
  img {
    width: 24px;
    transition: all 0.1s linear;
  }
  span {
    margin-right: 10px;
  }

  &:hover {
    background-color: #484260;
    color: #fff;
    img {
      filter: invert(100%);
    }
  }
}
