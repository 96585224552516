
.container-admin-apps {
    .form {
      width: 100%;
      margin: 20px auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
      column-gap: 32px;
      row-gap: 20px;
      justify-items: flex-start;
      align-items: center;
      //responsive
      @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      }
      &-title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0px;
        grid-column: 1 / -1;
      }
      &-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        &-input {
          width: 100%;
          height: 40px;
          font-size: 16px;
          background-color: #fff;
        }
        &-button {
          width: 100%;
          border: none;
          border-radius: 5px;
          background-color: #1e163b;
          color: #fff;
  
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          height: 40px;
          transition: background-color 0.3s;
          &:hover {
            background-color: rgba($color: #1e163b, $alpha: 0.8);
          }
        }
      }
    }
  }
  