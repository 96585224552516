.container-admin-group {
  .form {
    width: 100%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    column-gap: 32px;
    row-gap: 20px;
    justify-items: flex-start;
    align-items: center;
    //responsive
    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
    &-title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0px;
      grid-column: 1 / -1;
    }
    &-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      &-label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0px;
      }
      &-input {
        width: 100%;
      }
      &-checkbox {
        display: flex;
        align-items: center;
        margin-top: 10px;
        gap: 10px;
        &-input {
          margin-right: 10px;
        }
        &-label {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0px;
          min-width: 100px;
        }
      }
      &-button {
        width: 100%;
        border: none;
        border-radius: 5px;
        background-color: #1e163b;
        color: #fff;
        grid-area: 3/3;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        height: 40px;
        transition: background-color 0.3s;
        &:hover {
          background-color: rgba($color: #1e163b, $alpha: 0.8);
        }
      }
    }
  }
}
