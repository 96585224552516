body {
  background-image: url('../../assets/images/login/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
}

// Container of login view
.containerLogin {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  display: flex;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 0 auto;
  padding: 10px;
  border: none;
}

.button {
  width: 100%;
  margin-top: 10px;
  padding: 16px 20px;
  border: none;
  color: #fff;
  background: #201646;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 600;
}

.logo {
  width: 300px; /* o el tamaño que desees */
  height: auto; /* mantiene la relación de aspecto */
  margin-bottom: 25px; /* o el margen que desees */
}

/* LoginForm.module.css */
.error {
  margin-top: 0;
  color: rgb(185, 92, 92); /* Color rojo para los mensajes de error */
  font-size: 12px; /* Fuente más pequeña */
  height: 10px; /* Altura fija para evitar que los otros elementos se muevan */
  margin-left: 10px;
  width: 80%;
  text-align: left;
  margin-bottom: 10px;
}

.inputWithError {
  border: 2px solid rgb(185, 92, 92); /* Borde rojo para indicar un error */
}

.hidden {
  display: none; /* Oculta el elemento */
}

/* Estilos para el slider */
.toggleContainer {
  width: 80%;
  margin-top: 20px;
  display: flex;
  align-items: right;
  gap: 20px; /* Ajusta el espacio entre el switch y el texto */
}
/* Estilos para el switch (interruptor) */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0; /* Asegúrate de que no haya margen extra */
}

/* Estilos para el slider */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #8e6ec8;
}

input:focus + .slider {
  box-shadow: 0 0 1px #636363;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Estilo opcional para hacer el slider redondeado */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Estilos para la etiqueta del switch */
.switchLabel {
  font-size: 20px; /* Ajusta esto según tu diseño */
  color: #5d5e60; /* El color de texto que prefieras */
  /* Puedes añadir estilos adicionales si es necesario */
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* O la altura que desees para centrar verticalmente */
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  z-index: 1000; /* Asegúrate de que esté por encima de todo */
}

.spinner {
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Mobile (tamaño pequeño) */
@media (max-width: 768px) {
  .form {
    width: 100%; /* Ancho ajustado */
    padding: 5px; /* Padding ajustado */
  }

  .error {
    font-size: 10px; /* Fuente ajustada */
  }
}

/* Tablet (tamaño mediano) */
@media (min-width: 481px) and (max-width: 768px) {
  .form {
    width: 95%; /* Ancho ajustado */
    padding: 10px; /* Padding ajustado */
  }

  .logo {
    width: 300px; /* Ancho ajustado */
    margin-bottom: 20px; /* Margen ajustado */
  }

  .error {
    font-size: 11px; /* Fuente ajustada */
  }
}
